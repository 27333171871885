
import {html, css, LitElement} from 'lit'

export class inVisementLogo extends LitElement {

    static styles = css`

        span {
            color: var(--highlight-color);
            font-family: Var(--title-font);
            font-weight: normal;
            padding: 0;
            margin: 0;
        }
        
        strong {
            color: var(--accent-color);
        }

        small {
            font-size: 0.7em;
        }
    `

    render() {
        return html`
            <span>in<strong>V</strong>isement<small>.com</small></span>
        `
    }
}

customElements.define('invisement-logo', inVisementLogo);


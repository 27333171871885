/*
    ViseMenu components implements edit, cancel, save, and retrieve functionality for editable components
*/

import { LitElement, html, css } from "lit";
import { cancelIcon, editIcon, retrieveIcon, saveIcon } from "../utils/icons";
import "./vise-controller.ts"

class ViseMenu extends LitElement {

    contentVariableName = 'contentPage'

    editableComponents = ["business-card"]
    controllerComponent = "vise-controller"
    mode = 'readOnly'

    static styles = css`
        svg {
            height: 1.5em;
            padding: 0 5px;
        }
    `


    wrapControllerAround(el: Element) {
        const wrapper = document.createElement(this.controllerComponent)
        el.parentNode!.insertBefore(wrapper, el)
        wrapper.appendChild(el)
    }

    _removeController(el: Element) {
        const wrapper = el.parentElement

        wrapper!.parentElement!.insertBefore(el, wrapper)
        wrapper!.remove()
    }

    // wrap components around vise-controller
    attachViseController(selector: string) {

        document
            .querySelectorAll(selector)
            .forEach(el => {
                (el as HTMLElement).contentEditable = 'true'
                this.wrapControllerAround(el)
            })

    }

    detachViseController(selector: string) {

        document
            .querySelectorAll(selector)
            .forEach(el => {
                (el as HTMLElement).contentEditable = 'false'
                this._removeController(el)
            })
    
    }



    edit() {
        if (this.mode == 'edit') {
            return
        }

        this.editableComponents
            .forEach(componentName => {
                this.attachViseController(componentName)
            })
        
        this.mode = 'edit'
    }

    cancel() {
        if (this.mode != 'edit') {
            return
        }

        this.editableComponents
            .forEach(componentName => {
                this.detachViseController(componentName)
            })
        
        this.mode = 'readOnly'
    }

    saveHTML() {
        const content = document.documentElement.innerHTML
        localStorage.setItem(this.contentVariableName, content)
    }

    retrieveHTML() {
        const content = localStorage.getItem(this.contentVariableName)
        if (!content) {
            return
        }
        document.documentElement.innerHTML = content
    }

    render() {
        return html`

            <i @click="${this.edit}">${editIcon}</i>
        
            <i @click="${this.cancel}">${cancelIcon}</i>
        
            <i @click="${this.saveHTML}">${saveIcon}</i>

            <i @click="${this.retrieveHTML}">${retrieveIcon}</i>
        `
    }
}


customElements.define('vise-menu', ViseMenu)
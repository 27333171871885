
import { LitElement, html, css } from "lit"
import { calendarIcon, chatIcon, loginIcon, settingIcon } from "../../utils/icons"


export class ViseHeader extends LitElement {

    static styles = css`
        #container {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            justify-items: center;
            //justify-content: space-between;
            //background-color: var(--background-highlight);
            padding: 0.5em;
            position: sticky;
            height: 1.5em;
            color: var(--accent-color);
        }

        svg {
            height: 1.5em;
            padding: 0 5px;
        }
    `

    render() {return html`
        <div id="container">
            <vise-menu>
            </vise-menu>
    
            <invisement-logo style="font-size: 2em;">
            </invisement-logo>
            
            <vise-icons>
                ${calendarIcon}
                ${settingIcon}
                ${chatIcon}
                ${loginIcon}
            </vise-icons>

        </div>
    `}

}

customElements.define('vise-header', ViseHeader)

/*
    wrap other components (like business-card) and provide action icons (like add and remove)
    In edit mode (when a user wants to edit a page), this components will be called to wrap other components and provide edit actions such as add and remove

*/



import {html, css, LitElement} from 'lit'
import { addIcon, removeIcon } from '../utils/icons'

class ViseController extends LitElement {
    static styles = css`

        .icons svg  {
            height: 1.5em;
        }
        .icons {
            position: absolute;
            color: var(--highlight-color);
            margin-top: -1em;
        }
        .wrapper {
            width: fit-content;
        }
    `

    _add() {
        const el = this.cloneNode(true)
        this.after(el)
    }

    _remove() {
        this.remove()
    }

    render() {
        return html`
            <div class='wrapper'>
                <div class='icons'>
                    <i id='removeIcon' @click="${this._remove}">${removeIcon}</i> 
                    <i id='addIcon' @click="${this._add}">${addIcon}</i>
                </div>
                <slot></slot>
            </div>
        `
    }
}

customElements.define('vise-controller', ViseController);


